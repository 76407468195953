@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/moment-picker';
@import './src/scss/calendar';

.widget__input {
	//flex-wrap: wrap;
  overflow: auto;
}

.widget__flex_row {
  .widget__input-group {
    .widget__input-number {
      width: 80px !important;
    }
  }
}

.widget__input_full {
	max-width: fit-content;

  .widget__input-group {
    max-width: 100%;
    width: 100% !important;

    .widget__input-number {
      max-width: 100%;
      width: 100%;
    }
  }
}

.widget__result-wrap_calendar {
	margin: 0;
}

// .grey-container {
//   width: 600px;
//   margin: 10px auto;
//   background-color: lightgrey;
//   padding: 10px;
//   border-radius: 10px;
//   text-align: center;
//
//   h2 {
//     font-size: 64px;
//     padding: 10px 0;
//   }
// }

.text-capitalize {
  text-transform: capitalize;
}

.holiday {
  color: red;
}

.current {
  background-color: lightyellow;
}

.week-name.dow {
  &:nth-last-child(-n + 2) {
    color: red !important;
  }
}

.week-name:not(.dow) {
  &:first-child,
  &:last-child {
    color: red !important;
  }

  color: black;
}

.calendar {
    margin: auto;
    border-radius: 16px;
    box-shadow: 0 7px 29px 0 rgba(100, 100, 111, 0.2);
    padding: 1rem;

  table {
    td {
      font-size: small;
      width: 30px;
      height: 30px;
      text-align: center;
      border: 1px solid gray;
      padding: 0 5px;

      .day-title {
        font-size: 1rem;
      }

      .moon-data {
        margin-top: 10px;
        text-align: center;

        * {
          display: inline-block;
          width: 100%;
        }
      }
    }

    tr:first-child {
      font-weight: bold;

      td {
        height: auto;
        text-align: center;
        font-size: 1rem;
      }
    }
  }
}