@import 'variables';

.holiday {
  color: red;
}

.current {
 background-color: lightyellow;
}

.next, .prev {
  opacity: 0.3;
}

.calendar {
  justify-items: center;
  display: grid;
  //grid-template-columns: 60px minmax(auto, 100%) 60px;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;

  table {
    width: unset;

    td {
      font-size: small;
      width: 120px;
      height: 120px;
      text-align: right;
      border: 1px solid;
      padding: 0 5px;

      .calendar__day {
        font-size: 1rem;
      }

      .moon {
        margin-top: 10px;
        text-align: center;

        * {
          display: inline-block;
          width: 100%;
        }
      }
    }

    tr:first-child {
      font-weight: bold;

      td {
        height: auto;
        text-align: center;
        font-size: 1rem;
      }
    }
  }

	&__week-name.dow {
	  &:nth-last-child(-n + 2) {
	    color: red !important;
	  }
	}

	&__week-name:not(.dow) {
	  &:first-child,
	  &:last-child {
	    color: red !important;
	  }
	  color: black;
	}
}

.current {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    border: 2px solid red;
    background-color: lightgrey;
    width: 30px;
    height:  30px;
    z-index: -1;
    top: -3px;
    left: -5px;
  }
}